import React from "react"
import { RichText } from "prismic-reactjs"

const EmailSignup = ({ slice, map, mobile }) => {
  return (
    <section id="contact" className="email-signup">
      <div className="description">
        {slice.primary.email_title
          ? RichText.render(slice.primary.email_title)
          : "Untitled"}
        <a href={map.mapUrl} target="_blank">
          <img
            className="maps"
            src={map.childFile.childImageSharp.fixed.src}
            alt="alt"
          />
        </a>
      </div>
      <form
        name="contact-us"
        data-netlify="true"
        action="/cgi-bin/FormMail.pl"
        method="post"
      >
        <input
          type="hidden"
          name="recipient"
          value="support@myaenterprise.com"
        />
        <input
          type="hidden"
          name="subject"
          value="From the MYA enterprise form"
        />
        <input type="hidden" name="form-name" value="contact-us" />
        <input
          type="hidden"
          name="redirect"
          value="https://www.myaenterprise.com/thankyou"
        />

        {slice.fields.map((field, i) => {
          return (
            <input
              key={i}
              name={field.field_name}
              placeholder={field.field_name}
              required={field.required}
              type={field.field_type}
              className={field.classname}
            />
          )
        })}
        <button type="submit">
          {RichText.render(slice.primary.button_text)}
        </button>
      </form>
      <img src={mobile.src} alt="bg image" className="mobilebgemail" />
    </section>
  )
}

export default EmailSignup
