import React from "react"

const FourImages = ({ slice }) => {
  const images = slice.fields[0]

  return (
    <section id="four-images" className="img-row">
      <div className="img-column">
        <img className="show" src={images.image1.url} alt="hello" />
        <img className="none" src={images.image2.url} alt="hello" />
      </div>
      <div className="img-column-hide">
        <img className="none" src={images.image3.url} alt="hello" />
        <img className="none" src={images.image4.url} alt="hello" />
      </div>
    </section>
  )
}

export default FourImages
