import React from "react"

import "./navbar.scss"

import logo from "./../../../images/logo.png"

const Brand = () => {
  return (
    <div className="brand-box">
      <a href="/">
        <img className="brand" src={logo} alt="Company Logo" />
      </a>
    </div>
  )
}

export default Brand
