import React, { Component } from "react"
import { RichText } from "prismic-reactjs"
import ActiveThumbnailWindow from "./productGallery/active-thumbnail"
import ThumbnailGrid from "./productGallery/thumbnail-grid"

export default class HighlightSection extends Component {
  state = {
    thumbnails: [],
    activeIndex: 0,
  }
  componentDidMount() {
    const images = this.props.slice.fields
    this.setState({ thumbnails: images })
  }

  renderThumbnails = () => {
    const { thumbnails, activeIndex } = this.state
    if (thumbnails.length) {
      return <ActiveThumbnailWindow activeThumbnail={thumbnails[activeIndex]} />
    }
  }

  handleClick = e => {
    const newActiveIndex = e.target.getAttribute("data-index")
    this.setState({ activeIndex: newActiveIndex })
  }

  render() {
    const { thumbnails } = this.state
    return (
      <section id="gallery" className="highlight-section">
        <div className="highlight-content">
          {RichText.render(this.props.slice.primary.highlight_title)}
          {RichText.render(this.props.slice.primary.highlight_text)}
        </div>
        <div className="highlight-image">
          {this.renderThumbnails()}
          <ThumbnailGrid
            handleClick={this.handleClick}
            thumbnails={thumbnails}
          />
        </div>
      </section>
    )
  }
}
