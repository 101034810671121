import React, {Component}from "react"
import BackgroundImage from "gatsby-background-image"
import { RichText } from "prismic-reactjs"

class CallToAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0, // or your default width here
      hrefprod: false
    }
  }
  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth, hrefprod: window.location.href.indexOf("productos") !== -1 })
  }

  // rest of code

  render(){
    const blackButton = this.props.slice.primary.black_button
    const imgHome = this.state.width <= 610 ? <img src={this.props.couple.src} alt="bg image" className="mobilebg" /> :  <img className="home-img" src={blackButton.url} alt={blackButton.alt} />
    const imgProd = this.state.width <= 610 ? <img src={blackButton.url} alt="bg image" className="mobilebg" /> :  <img className="home-img" src={blackButton.url} alt={blackButton.alt} />

    return (
      <section id="hero" className="call-to-action">
      <div className="home">
        {this.state.hrefprod ? imgProd : imgHome}
      </div>
      <div className="hero-title">
        {this.props.slice.primary.action_text
          ? RichText.render(this.props.slice.primary.action_text)
          : " "}
      </div>
    </section>
    )
  }
}

export default CallToAction
