import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"

import { linkResolver } from "../../../utils/link-resolver"

import Mex from "./../../../images/mx.png"
import Usa from "./../../../images/usa.jpg"
import { useSpring, animated } from "react-spring"

const CollapseMenu = props => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })
  if (props.navbarState === true) {
    const prop = props.props.props
    const menuLink = prop.menuLinks.menu_links
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
        }}
      >
        <NavLinks>
          {menuLink
            ? menuLink.map((menuLink, index) => {
                if (menuLink.local) {
                  return (
                    <li key={index}>
                      <a
                        href={"/#" + menuLink.local}
                        key={index}
                        onClick={props.handleNavbar}
                      >
                        {menuLink.label
                          ? RichText.asText(menuLink.label)
                          : "Untitled"}
                      </a>
                    </li>
                  )
                } else {
                  return (
                    <li key={index}>
                      <Link to={linkResolver(menuLink.link._meta)} key={index}>
                      {menuLink.label
                        ? RichText.asText(menuLink.label)
                        : "Untitled"}
                    </Link>
                    </li>
                  )
                }
              })
            : null}
          {prop.activeDoc
            ? prop.activeDoc.alternateLanguages.map((altLang, index) => {
                return (
                  <Link
                    className="lang"
                    key={index}
                    to={linkResolver(altLang)}
                    onClick={props.handleNavbar}
                  >
                    {altLang.lang === "en-us" ? (
                      <img className="flag" src={Usa} alt="logo" />
                    ) : (
                      <img className="flag" src={Mex} alt="logo" />
                    )}
                  </Link>
                )
              })
            : null}
          <span className="slash">{"/"}</span>
          <Link
            className="lang"
            to={linkResolver(prop.activeDoc)}
            onClick={props.handleNavbar}
          >
            {prop.activeDoc.lang === "en-us" ? (
              <img className="flag" src={Usa} alt="logo" />
            ) : (
              <img className="flag" src={Mex} alt="logo" />
            )}
          </Link>
        </NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}

export default CollapseMenu

const CollapseWrapper = styled(animated.div)`
  background: white;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
  -webkit-box-shadow: -2px 10px 22px -9px rgba(194, 192, 194, 1);
  -moz-box-shadow: -2px 10px 22px -9px rgba(194, 192, 194, 1);
  box-shadow: -2px 10px 22px -9px rgba(194, 192, 194, 1);
  z-index: 1;
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;

  & li {
    transition: all 300ms linear 0s;
    padding-bottom: 10px;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #4d4d4d;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #82cc00;
      border-bottom: 1px solid #82cc00;
    }
  }
`
