import React, {Component}from "react"
import BackgroundImage from "gatsby-background-image"
// import MobileImg form "./../../images/" 

class FullWidthImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0, // or your default width here
    }
  }
  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth, hrefprod: window.location.href.indexOf("productos") !== -1 })
  }

  // rest of code

  render(){
    const imgCorrect = this.state.width <= 610 ? <img src={this.props.mobile.src} alt="bg image" className="mobilebg" /> : <BackgroundImage className="fiximg" fluid={this.props.desktop} />
    const whichPage = this.state.hrefprod ? "full_width_image_prod" : "full_width_image"
    return (
      <section id="fixed-img" className={whichPage}>
        {imgCorrect}
      </section>
    )
  }
}

export default FullWidthImage