import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SliceZone from "../components/SliceZone"

export const query = graphql`
  query myHome($lang: String) {
    desktop: file(relativePath: {eq: "bbdesktop.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp

        }
      }
    }
    mobile: file(relativePath: {eq: "bbmobile.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    couple: file(relativePath: {eq: "couple.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 610) {
					...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    staticMap {
      mapUrl
      childFile {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
    prismic {
      allHomepages(lang: $lang) {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
              alternateLanguages {
                uid
                id
                type
                lang
              }
            }
            page_content {
              __typename
              ... on PRISMIC_HomepagePage_contentCall_to_action {
                type
                primary {
                  action_title
                  action_text
                  white_button
                  black_button
                }
              }
              ...on PRISMIC_HomepagePage_contentFull_width_image{
                type
                primary{
                  background_image
                }
              }
              ... on PRISMIC_HomepagePage_contentQuote {
                type
                label
                primary {
                  quote
                  author
                  quoteiname
                }
              }
              ... on PRISMIC_HomepagePage_contentEmail_signup {
                type
                primary {
                  email_title
                  email_text
                  label
                  placeholder
                  button_text
                  description
                  location
                  button_text
                }
                fields {
                  field_name
                  field_type
                  required
                  classname
                }
              }
              ... on PRISMIC_HomepagePage_contentFour_images {
                type
                label
                fields {
                  image1
                  image2
                  image3
                  image4
                }
              }
              ... on PRISMIC_HomepagePage_contentCertificates {
                type
                label
                primary {
                  first_cert
                  second_cert
                }
              }
              ... on PRISMIC_HomepagePage_contentHighlight_section {
                type
                label
                primary {
                  highlight_text
                  highlight_title
                }
                fields {
                  product
                  mililiters
                }
              }
            }
          }
        }
      }
      allMenus(lang: $lang) {
        edges {
          node {
            menu_links {
              label
              local
              footer
              link {
                ... on PRISMIC_Homepage {
                  _meta {
                    uid
                    id
                    lang
                    type
                    alternateLanguages {
                      uid
                      id
                      lang
                      type
                    }
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                    id
                    lang
                    type
                    alternateLanguages {
                      uid
                      id
                      lang
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {

  const prismicContentHome = data.prismic.allHomepages.edges[0]
  if (!prismicContentHome) return null
  const homepage = prismicContentHome.node

  const prismicContentMenu = data.prismic.allMenus.edges[0]
  if (!prismicContentMenu) return null
  const menu = prismicContentMenu.node


  const mapa = data.staticMap

  const desktop = data.desktop.childImageSharp.fluid
  const mobile = data.mobile.childImageSharp.fluid
  const couple = data.couple.childImageSharp.fluid

  return (
    <Layout menuLinks={menu} activeDoc={homepage._meta}>
      <SliceZone mobile={mobile} desktop={desktop} slices={homepage.page_content} mapa={mapa} couple={couple}/>
    </Layout>
  )
}
export default Homepage
