import React from "react"
import { RichText } from "prismic-reactjs"
import "./product.scss"

const ActiveThumbnailWindow = ({ activeThumbnail }) => {
  return (
    <div className="active-thumbnail">
      {RichText.render(activeThumbnail.mililiters)}
      <img src={activeThumbnail.product.url} alt="alt" />
    </div>
  )
}
export default ActiveThumbnailWindow
