import React, { Component } from "react"
import Navbar from "./navbar/Navbar"

class Menu extends Component {
  state = {
    navbarOpen: false,
  }

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen })
  }

  render() {
    const props = this.props
    if (!props) return null
    return (
      <Navbar
        props={props}
        navbarState={this.state.navbarOpen}
        handleNavbar={this.handleNavbar}
      />
    )
  }
}

export default Menu
