import React from "react"
import "./product.scss"

const Thumbnail = ({ imgUrl, handleClick, index }) => {
  return (
    <div className="thumbnail">
      <img onClick={handleClick} src={imgUrl} data-index={index} />
    </div>
  )
}

export default Thumbnail
