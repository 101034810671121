import React from "react"
import {
  CallToAction,
  HighlightSection,
  EmailSignup,
  FourImages,
  Quote,
  Certificates,
  FullWidthImage,
  CardsCarousel,
} from "./slices"

/* Go through the slices of the post and render the appropriate one */
const SliceZone = ({ slices, mapa, mobile, desktop, couple }) => {
  return slices.map((slice, index) => {
    switch (slice.type) {
      case "call_to_action":
        return <CallToAction key={`slice-${index}`} slice={slice} couple={couple} />

      case "email_signup":
        return <EmailSignup key={`slice-${index}`} slice={slice} map={mapa} mobile={mobile}/>

      case "highlight_section":
        return <HighlightSection key={`slice-${index}`} slice={slice} />

      case "four_images":
        return <FourImages key={`slice-${index}`} slice={slice} />

      case "quote":
        return <Quote key={`slice-${index}`} slice={slice} />

      case "certificates":
        return <Certificates key={`slice-${index}`} slice={slice} />

      case "full_width_image":
        return <FullWidthImage key={`slice-${index}`} slice={slice} desktop={desktop} mobile={mobile} />

      case "cards_carousel":
        return <CardsCarousel key={`slice-${index}`} slice={slice} />

      default:
        return null
    }
  })
}

export default SliceZone
