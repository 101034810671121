import React from "react"
import Thumbnail from "./thumbnail"
import "./product.scss"

const ThumbnailGrid = ({ thumbnails, handleClick }) => {
  return (
    <div className="thumbnail-grid">
      {thumbnails.map((thumbnail, i) => {
        return (
          <Thumbnail
            key={i}
            imgUrl={thumbnail.product.url}
            handleClick={handleClick}
            index={i}
          />
        )
      })}
    </div>
  )
}
export default ThumbnailGrid
