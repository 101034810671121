import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/link-resolver"
import Footerimg from "./../../images/footer.png"

const Footer = ({ menuLinks }) => {
  const lang = menuLinks.menu_links[2].link._meta.lang
  return (
    <footer>
      <div className="footertitle">
        {lang === "es-mx" ? (
          <p style={{ color: "white", fontSize: "20px" }}>
            <strong style={{ color: "white", fontSize: "25px" }}>
              LIMPIAR
            </strong>{" "}
            el contacto
          </p>
        ) : (
          <p style={{ color: "white", fontSize: "20px" }}>
            <strong style={{ color: "white", fontSize: "25px" }}>
              CLEANSE
            </strong>{" "}
            the contact
          </p>
        )}
      </div>
      <div className="links">
        {menuLinks.menu_links
          ? menuLinks.menu_links.map((menuLink, index) => {
              if (menuLink.local) {
                return (
                  <div key={index} className="link">
                    <a href={"#" + menuLink.local} >
                      {menuLink.label
                        ? RichText.asText(menuLink.label)
                        : "Untitled"}
                    </a>
                    <p>{menuLink.footer}</p>
                  </div>
                )
              } else {
                return (
                  <div key={index} className="link">
                    <Link to={linkResolver(menuLink.link._meta)} >
                      {menuLink.label
                        ? RichText.asText(menuLink.label)
                        : "Untitled"}
                    </Link>
                    <p>{menuLink.footer}</p>
                  </div>
                )
              }
            })
          : null}
      </div>
      <div className="logo">
        <img src={Footerimg} alt="logo" />
      </div>
      <div className="address">
        <strong>1080 N Batavia st</strong>
        <p>Suite P Orange CA</p>
      </div>
    </footer>
  )
}

export default Footer
