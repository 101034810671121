
import React, { Component } from "react"
class Certificates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0, // or your default width here
    }
  }
  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth, hrefprod: window.location.href.indexOf("productos") !== -1 })
  }

  // rest of code

  render(){
    const image = this.props.slice.primary

    const whichPage = this.state.hrefprod ? "certificate-section" : "certificate-section-home"
    return (
      <section className={whichPage}>
      <div className="cert">
        <img className="certimg" src={image.first_cert.url} alt="hello" />
      </div>
      <div className="cert">
        <img className="certimg" src={image.second_cert.url} alt="hello" />
      </div>
    </section>
    )
  }
}

export default Certificates