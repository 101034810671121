import React from "react"
import { RichText } from "prismic-reactjs"

const CardsCarousel = ({ slice }) => {
  return (
    <section id="cards-carousel" className="cards-carousel">
      <div className="title">
        {slice.primary.title ? RichText.render(slice.primary.title) : " "}
      </div>
      <div className="products">
        {slice.fields.map((prod, i) => {
          return (
            <div className="prod" key={i}>
              <img className="prod-img" src={prod.image.url} alt={"product"} />
              <div className="content">{RichText.render(prod.content)}</div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default CardsCarousel
