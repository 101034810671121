import React, { Fragment } from "react"
import { Helmet } from "react-helmet"

import Menu from "./menu"
import Footer from "./footer"

import { ThemeProvider } from "../../context/theme-context"
import "../../stylesheets/main.scss"

const Layout = props => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"MYA Enterprise"}</title>
        <meta name="description" content={"Mya enterprise site"} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider>
        <Menu menuLinks={props.menuLinks} activeDoc={props.activeDoc} />
        <main>{props.children}</main>
        <Footer menuLinks={props.menuLinks}/>
      </ThemeProvider>
    </Fragment>
  )
}

export default Layout