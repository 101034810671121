import React from "react"
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"

import { linkResolver } from "../../../utils/link-resolver"

import Mex from "./../../../images/mx.png"
import Usa from "./../../../images/usa.jpg"

import Brand from "./Brand"
import BurgerMenu from "./BurgerMenu"
import CollapseMenu from "./CollapseMenu"

import "./navbar.scss"

const Navbar = props => {
  const barAnimation = useSpring({
    from: { transform: "translate3d(0, -10rem, 0)" },
    transform: "translate3d(0, 0, 0)",
  })

  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  })
  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand />
          <NavLinks style={linkAnimation}>
            {props.props.activeDoc
              ? props.props.activeDoc.alternateLanguages.map(
                  (altLang, index) => {
                    return (
                      <Link
                        className="lang"
                        key={index}
                        to={linkResolver(altLang)}
                      >
                        {altLang.lang === "en-us" ? (
                          <img className="flag" src={Usa} alt="logo" />
                        ) : (
                          <img className="flag" src={Mex} alt="logo" />
                        )}
                      </Link>
                    )
                  }
                )
              : null}
            <span className="slash">{"/"}</span>
            <Link className="lang" to={linkResolver(props.props.activeDoc)}>
              {props.props.activeDoc.lang === "en-us" ? (
                <img className="flag" src={Usa} alt="logo" />
              ) : (
                <img className="flag" src={Mex} alt="logo" />
              )}
            </Link>
            {props.props.menuLinks
              ? props.props.menuLinks.menu_links.map((menuLink, index) => {
                  if (menuLink.local) {
                    return (
                      <a href={"/#" + menuLink.local} key={index}>
                        {menuLink.label
                          ? RichText.asText(menuLink.label)
                          : "Untitled"}
                      </a>
                    )
                  } else {
                    return (
                      <Link to={linkResolver(menuLink.link._meta)} key={index}>
                        {menuLink.label
                          ? RichText.asText(menuLink.label)
                          : "Untitled"}
                      </Link>
                    )
                  }
                })
              : null}
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        mx={Mex}
        usa={Usa}
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
        props={props}
      />
    </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 1;
  font-size: 1rem;
`

const FlexContainer = styled.div`
  z-index: 1;
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: 5rem;
`

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    font-size: 110%;
    color: #233F80;
    // color: #4d4d4d;
    // font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #82cc00;
      border-bottom: 1px solid #82cc00;
    }

    @media (max-width: 798px) {
      display: none;
    }
  }
`

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 798px) {
    display: none;
  }
`
