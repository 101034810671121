import React from "react"
import { RichText } from "prismic-reactjs"

const Quote = ({ slice }) => {
  return (
    <section className="quote-section" id="ourimpact">
      <div className="quote">
        {RichText.render(slice.primary.quote)}
      </div>
    </section>
  )
}

export default Quote
